import cn from "classnames";
import { IconType } from "react-icons/lib";
import { AiFillInfoCircle } from "react-icons/ai";

import React, { forwardRef, useEffect, useRef, useState } from "react";
import { MdClose, MdSearch } from "react-icons/md";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

import "./TextInput.scss";

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  Icon?: IconType;
  error?: boolean;
  errorMessage?: string;
  wrapperClassName?: string;
  onClickClose?: (el: HTMLInputElement) => void;
  search?: boolean;
  handleValue?: (email: string) => void;
  startValue?: string;
  numberNeeded?: boolean;
  password?: boolean;
  address?: boolean;
}

export const TextInput = forwardRef(
  (
    {
      onClickClose,
      Icon,
      error,
      errorMessage,
      wrapperClassName,
      search,
      onChange,
      handleValue,
      startValue,
      numberNeeded,
      password,
      address,
      ...props
    }: TextInputProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState(startValue);
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
      setInputValue(startValue);
    }, [startValue]);

    useEffect(() => {
      handleValue && handleValue(inputValue || "");
    }, [handleValue, inputValue]);

    const validateNumber = (e: any) => {
      const value = e.target.value.replace(/\D/g, "");
      setInputValue(value);

    };

    return (
      <>
        <div
          ref={ref}
          className={cn(wrapperClassName, "text-input-wrapper", {
            ["text-input-error"]: error,
            ["mb-0"]: errorMessage
          })}
        >
          <input
            ref={inputRef}
            {...props}
            className="text-input"
            value={inputValue}
            onChange={(e) => {
              onChange && onChange(e);

              numberNeeded ? validateNumber(e) : setInputValue(e.target.value);

            }}
            type={password ? (visible ? "text" : "password") : props.type}
          />
          {search && <MdSearch className="search-icon" />}
          <MdClose
            className="search-input-close-icon"
            onClick={() =>
              onClickClose &&
              inputRef?.current &&
              onClickClose(inputRef?.current)
            }
          />
          {error&&!password ? (
            <AiFillInfoCircle className="text-input-icon" />
          ) : (
            Icon && <Icon className="text-input-icon" />
          )}
          {password && (
            <span
              className={
                cn("text-input-icon","password-toggle",{
                  ["password-toggle--error"]:error
                })
              }
              onClick={() => setVisible((prev) => !prev)}
            >
              {visible && <IoMdEye />}
              {!visible && <IoMdEyeOff />}
            </span>
          )}
        </div>
        {errorMessage && (
          <div className="text-input-error-message mb-40">{errorMessage}</div>
        )}
      </>
    );
  }
);
