import { useParams } from "react-router-dom"; // ✅ Get `id` from URL
import { useLabAnalysisInfo } from "../hooks/useLabAnalysisInfo"; // ✅ Import Hook
import { Breadcrumb, Container } from "components";
import previewVideo from "../../../../assets/videos/lab-preview.mp4";
import { useState } from "react";
import { BranchesInfoMap } from "common/BranchesInfo/BranchesInfoMap";
import "./LabAnalysisInfo.scss";
import { convertRawToHtml } from "utils";

export function LabAnalysisInfo() {
  const { id } = useParams<{ id: string }>(); // ✅ Get ID from URL
  const { analysis, isLoading, isError } = useLabAnalysisInfo(Number(id));

  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlay = () => setIsPlaying((prev) => !prev);

  if (isLoading) return <p>Loading...</p>;
  if (isError || !analysis) return <p>Error loading analysis.</p>;

  return (
    <Container>
      <div className="laboratory-analysis">
        {/* ✅ Breadcrumbs remain unchanged */}
        <Breadcrumb
          className="laboratory-analysis__breadcrumb"
          links={[
            { label: "მთავარი", value: "/" },
            { label: "ლაბორატორია", value: "/lab" },
            { label: "კვლევები", value: "/lab/analysis" },
            { label: analysis.title, value: `/lab/analysis/${analysis.id}` }, // ✅ Dynamic Title
          ]}
        />

        <div className="content-container">
          <div>
            {/* ✅ Dynamic Title */}
            <h1 className="analysis-name">{analysis.title}</h1>

            {/* ✅ Dynamic Content */}
            <div
              className="text-container"
            />
            {convertRawToHtml(analysis.content)}
          </div>

          <div>
            {/* <div style={{ position: "relative" }}>
              <video loop controls className="video-preview" onPlay={handlePlay} onPause={handlePlay}>
                <source src={previewVideo} type="video/mp4" />
              </video>
              {!isPlaying && <button onClick={handlePlay} className="play-button">►</button>}
            </div>

            <div className="map-container">
              <BranchesInfoMap />
              <p className="map-container__title">ფილიალები ჩემთან ახლოს</p>
            </div> */}
          </div>
        </div>
      </div>
    </Container>
  );
}
