import { useQuery } from "react-query";
import { publicRequest } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const useLabAnalysisInfo = (id: number) => {
  const { language } = useSelector((state: RootState) => state.global); // ✅ Get locale
  const { data, isLoading, isError } = useQuery(
    ["labAnalysis", id, language], // Unique cache key
    () =>
      publicRequest({
        url: `/api/lab_analysis/${id}/${language}`,
        method: "get",
      }),
    {
      enabled: !!id, // ✅ Fetch only if ID is provided
    }
  );

  return {
    analysis: data?.data,
    isLoading,
    isError,
  };
};
